<template lang="pug">
.container
  .filters
    .main-filters
      .filter
        i.filter-label First name
        input(type='text', v-model='filters.first_name')
      .filter
        i.filter-label Last name
        input(type='text', v-model='filters.last_name')
    .secondary-filters
      .filter
        i.filter-label English level
        select(v-model="filters.english_level")
          option(disabled value="") English level
          option(value="A1") A1
          option(value="A2") A2
          option(value="B1") B1
          option(value="B2") B2
          option(value="C1") C1
          option(value="C2") C2
      .filter
        i.filter-label Current position
        input(type='text', v-model='filters.current_position')
      .filter
        i.filter-label Next call
        input(type="datetime-local", v-model='filters.next_call')
      .filter
        i.filter-label Career starts
        input(type='date', v-model='filters.career_starts')
  button.search-button(@click="$store.dispatch('searchCandidates')") Search

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CandidateSearchFilters',
  computed: {
    ...mapGetters([
      'getFilters',
    ]),
    filters: (store) => store.getFilters,
  },
  methods: {},
};
</script>

<style scoped lang="stylus">
.filters
  display flex

.main-filters
  width 280px;
  display flex
  flex-direction column
  margin-right 30px

.secondary-filters
  width 280px

.filter
  display flex
  justify-content space-between
  &:not(:last-child)
    margin-bottom 10px

.search-button
  margin-top 10px
  width 360px
</style>
