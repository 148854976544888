<template lang="pug">
.not-found
  router-link(
    :to="{name: 'home'}"
  ) Back home page
  h1 PageNotFound
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped lang="stylus">

</style>
