<template lang="pug">
.candidate
  BackButton(back_url_name="candidates-search")
  router-link(
    v-if="typeof candidate.id !== 'undefined'",
    :to="{ name: 'candidates-edit', params: { id: candidate.id } }"
  ) Edit
  h1 {{  $route.params.id }}
  .name {{ candidate.first_name }} {{ candidate.last_name }}
  .cv_path
    router-link(
    target="_blank",
      v-if="typeof candidate.id !== 'undefined'",
      :to="{ name: 'pdf-viewer', params: { id: candidate.id } }"
    ) cv_path {{ candidate.cv_path }} {{ candidate.id}}
  .current-position current_position {{ candidate.current_position }}
  .experience
    i experience
    i(v-if="candidate.experience.years") {{ candidate.experience.years }} years
    i(v-if="candidate.experience.months") , {{ candidate.experience.months }}
  .citizen citizen{{ candidate.citizen }}
  .current_location current_location {{ candidate.current_location }}
  .english_level english_level {{ candidate.english_level }}
  .native_speak_language native_speak_language {{ candidate.native_speak_language }}
  .other_speak_languages other_speak_languages {{ candidate.other_speak_languages }}
  .education_level education_level {{ candidate.education_level }}
  .notes notes {{ candidate.notes }}
  .skills skills {{ candidate.skills }}
  .next_call next_call {{ candidate.next_call }}
</template>

<script>
import { AxiosApi } from '@/services/axios-api';
import BackButton from '@/components/appSections/BackButton.vue';

export default {
  name: 'Candidate',
  components: { BackButton },
  data() {
    return {
      show_back_button: false,
      candidate: {
        experience: {
          years: 0,
          months: 0,
        },
      },
    };
  },
  methods: {},
  beforeCreate() {
    AxiosApi.get(`/candidate/${this.$route.params.id}/`)
      .then(({ data }) => {
        this.candidate = data;
      });
  },
};
</script>

<style scoped>

</style>
