<template lang="pug">
.candidates-create
  .fields
    .input-field
      .label first name
      input(type="text", v-model="candidate.first_name")
    .input-field
      .lable last name
      input.field(type="text", v-model="candidate.last_name")
    .input-field
      .label current position
      input.field(type="text", v-model="candidate.current_position")
  .buttons
    button(@click="$router.go(-1)") Cancel
    button(@click="createCandidate") Create
  .error {{ this.error }}
</template>

<script>

import { AxiosApi } from '@/services/axios-api';

export default {
  name: 'CandidateCreate',
  data() {
    return {
      candidate: {
        first_name: '',
        last_name: '',
        current_position: '',
        cv_path: '',
      },
      error: '',
    };
  },
  methods: {
    createCandidate() {
      this.error = '';
      AxiosApi.post('/candidate/', {
        first_name: this.candidate.first_name,
        last_name: this.candidate.last_name,
        current_position: this.candidate.current_position,
      }).then((response) => {
        const candidateId = response.data.id;
        this.$router.push({ name: 'candidates-edit', params: { id: candidateId } });
      }).catch((error) => {
        this.error = error.response.statusText;
      });
    },
  },
};
</script>

<style scoped lang="stylus">
</style>
