<template lang="pug">
.dashboard Dashboard
</template>

<script>
export default {
  name: 'Dashboard',
  // props: ['id', 'title', 'description', 'to_name'],
};
</script>

<style scoped lang="stylus">

</style>
