<template lang="pug">
.pdf-layout
  router-view
</template>

<script>
export default {
  name: 'PdfLayout',
};
</script>

<style scoped lang="stylus">
.pdf-layout
  height 100%
</style>
