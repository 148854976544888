<template lang="pug">
.landing-page
  router-view
</template>

<script>
export default {
  name: 'PublicPageLayout',
};
</script>

<style scoped lang="stylus">
.landing-page
  margin-top 60px
  text-align center
</style>
