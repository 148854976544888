<template lang="pug">
.candidates-edit
  .fields
    .input-field(v-if="candidate.cv_path")
      .cv {{ candidate.cv_path }}
        .remove-cv(@click="removeCv") Remove
    .input-field(v-else)
      input(type="file"
        accept="application/pdf"
        class="fileinput"
        @change="onFileChange"
      )
    .input-field
      .label first_name
      input.field(type="text", v-model="candidate.first_name")
    .input-field
      .label last_name
      input.field(type="text", v-model="candidate.last_name")
    .input-field
      .label current_position
      input.field(type="text", v-model="candidate.current_position")
    .input-field
      .label contacts
      input.field(type="text", v-model="candidate.contacts")
    .input-field
      .label citizen
      input.field(type="text", v-model="candidate.citizen")
    .input-field
      .label current_location
      input.field(type="text", v-model="candidate.current_location")
    .input-field
      .label english_level
      input.field(type="text", v-model="candidate.english_level")
    .input-field
      .label native_speak_language
      input.field(type="text", v-model="candidate.native_speak_language")
    .input-field
      .label other_speak_languages
      input.field(type="text", v-model="candidate.other_speak_languages")
    .input-field
      .label education_level
      input.field(type="text", v-model="candidate.education_level")
    .input-field
      .label notes
      input.field(type="text", v-model="candidate.notes")
    .input-field
      .label next_call
      input.field(type="text", v-model="candidate.next_call")
    .input-field
      .label skills
      input.field(type="text", v-model="candidate.skills")
    .input-field
      .label.
        career_starts ({{ candidate.experience.years }}
        years {{ candidate.experience.months }} months)
      input.field(type="text", v-model="candidate.career_starts")
    .input-field
      .label experience
      input.field(type="text", v-model="candidate.experience")
  .buttons
    button(@click="$router.go(-1)") Cancel
    button(@click="submitCandidate") Save
</template>

<script>
import { AxiosApi } from '@/services/axios-api';

export default {
  name: 'CandidateEdit',
  data() {
    return {
      candidate: {
        id: null,
        first_name: null,
        last_name: null,
        current_position: null,
        cv_path: null,
        contacts: {},
        citizen: null,
        current_location: null,
        english_level: null,
        native_speak_language: null,
        other_speak_languages: null,
        education_level: null,
        notes: null,
        next_call: null,
        skills: [],
        career_starts: null,
        experience: {},
      },
      file: null,
      error: null,
      save_file_error: null,
    };
  },
  methods: {
    onFileChange(e) {
      const { files } = e.target;
      if (files.length) {
        const [file] = files;
        const formData = new FormData();
        formData.append('file', file);
        this.file = file;
        this.candidate.cv_path = this.file.name;
      }
    },
    removeCv() {
      this.file = null;
      this.candidate.cv_path = null;
    },
    async submitCandidate() {
      this.error = null;
      if (this.candidate.cv_path) {
        await this.submitFile();
      }
      if (!this.save_file_error) {
        await this.submitCandidateObj();
      }
      if (!this.save_file_error) {
        this.$router.push({ name: 'candidate-card', params: { id: this.candidate.id } });
      }
    },
    async submitCandidateObj() {
      await AxiosApi.put(`/candidate/${this.$route.params.id}/`, {
        ...this.candidate,
      })
        .then(({ data }) => {
          this.error = null;
          this.candidate = data;
        }).catch((error) => {
          this.error = error.response.statusText;
        });
    },
    async submitFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      await AxiosApi.post(`file/upload/?candidate_id=${this.candidate.id}`, formData, {
        headers: {
          'Content-Type': 'application/multipart/form-data',
        },
      }).then(() => {
        this.save_file_error = false;
      })
        .catch(() => {
          this.save_file_error = true;
        });
    },
  },
  beforeCreate() {
    AxiosApi.get(`/candidate/${this.$route.params.id}/`)
      .then(({ data }) => {
        this.candidate = data;
      });
  },
};
</script>

<style scoped lang="stylus">

</style>
