import Vue from 'vue';
import VueRouter from 'vue-router';

import vacanciesRouter from '@/router/vacanciesRouter';
import candidatesRouter from '@/router/candidatesRouter';
import publicPagesRouter from '@/router/publicPagesRouter';

import Home from '@/views/Home.vue';
import PdfViewer from '@/views/files/PdfViewer.vue';
import SomethingWentWrong from '@/views/errors/SomethingWentWrong.vue';
import PageNotFound from '@/views/errors/PageNotFound.vue';

// eslint-disable-next-line no-unused-vars
import PublicPageLayout from '@/layouts/PublicPageLayout.vue';
// eslint-disable-next-line no-unused-vars
import PdfLayout from '@/layouts/PdfLayout.vue';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...candidatesRouter,
    ...vacanciesRouter,
    ...publicPagesRouter,
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        requiresLogin: true,
        title: 'Home page',
      },
    },
    {
      path: '/download/:id/',
      name: 'pdf-viewer',
      component: PdfViewer,
      meta: {
        layout: 'PdfLayout',
        requiresLogin: true,
        title: 'CV',
      },
    },
    {
      path: '/something-went-wrong/',
      name: 'something-went-wrong',
      component: SomethingWentWrong,
      meta: {
        layout: 'PublicPageLayout',
        title: 'Oooooooops',
      },
    },
    {
      path: '*',
      name: 'not-found',
      component: PageNotFound,
      meta: {
        layout: 'PublicPageLayout',
        title: '404',
      },
    },
  ],
});
