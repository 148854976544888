import { AxiosApi } from '@/services/axios-api';

export default {
  state: {
    candidateSearchResult: [],
    nextPage: '',
    isSearching: false,
    filters: {
      first_name: '',
      last_name: '',
      notes: '',
      next_call: '',
      career_starts: '',
      current_position: '',
      english_level: '',
      limit: 10,
    },
  },
  mutations: {
    pushCandidates(state, candidates) {
      state.candidateSearchResult.push(...candidates);
    },
    setNextPage(state, nextPage) {
      state.nextPage = nextPage;
    },
    cleanSearchResults(state) {
      state.candidateSearchResult = [];
      state.nextPage = [];
    },
  },
  getters: {
    getCandidateSearchResult: (state) => state.candidateSearchResult,
    getNextPage: (state) => state.nextPage,
    getIsSearching: (state) => state.isSearching,
    getFilters: (state) => state.filters,
  },
  actions: {
    async searchCandidates(context) {
      context.commit('cleanSearchResults');
      let filters = [];
      Object.entries(context.state.filters).forEach((key) => {
        if (key[1]) {
          // eslint-disable-next-line prefer-destructuring
          filters.push(`${key[0]}=${key[1]}`);
        }
      });
      filters = filters.length ? `?${filters.join('&')}` : '';
      const url = `/candidates/${filters}`;
      await AxiosApi.get(url)
        .then(({ data }) => {
          context.commit('pushCandidates', data.records);
          context.commit('setNextPage', data.next_page);
        });
    },
    async getNextList(context) {
      const nextPage = context.getters.getNextPage;
      const url = `/candidates/?next_page=${nextPage}/`;
      await AxiosApi.get(url)
        .then(({ data }) => {
          context.commit('pushCandidates', data.records);
          context.commit('setNextPage', data.next_page);
        });
    },
  },
};
