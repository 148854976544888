// eslint-disable-next-line import/no-extraneous-dependencies
import Vuex from 'vuex';
import Vue from 'vue';
import candidateList from './components/candidateList';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    candidateList,
  },
});
