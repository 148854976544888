<template lang="pug">
.landing
    AuthButtons
    h1 This is Landing Page
</template>

<script>
import AuthButtons from '@/components/auth/AuthButtons.vue';

export default {
  name: 'Landing',
  components: {
    AuthButtons,
  },
};
</script>

<style scoped lang="stylus">

</style>
