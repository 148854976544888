<template lang="pug">
.home
  h1 This is home page for auth users.
  h2 Hi {{ user.username }}
  .user {{ user }}
</template>

<script>
import { AxiosApi } from '@/services/axios-api';

export default {
  name: 'Home',
  data() {
    return {
      user: {},
    };
  },
  async beforeCreate() {
    AxiosApi.get('/user/')
      .then((response) => {
        this.user = response.data;
      });
  },
};
</script>

<style scoped lang="stylus">

</style>
