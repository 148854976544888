<template lang="pug">
  .back-button
    router-link(
    tag="button",
    :to="{ name: back_url_name || 'home'}",
  ) <<< Back
</template>

<script>
export default {
  name: 'BackButton',
  props: ['back_url_name'],
};
</script>

<style scoped lang="stylus">

</style>
