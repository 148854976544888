<template lang="pug">
.something-went-wrong
  h1 SomethingWentWrong
</template>

<script>
export default {
  name: 'SomethingWentWrong',
};
</script>

<style scoped lang="stylus">

</style>
