<template lang="pug">
.candidates
  h1 Search candidates.
  CandidateSearchFilters
  CandidateSearchResult
</template>

<script>
import CandidateSearchFilters from '@/components/candidateSearch/CandidateSearchFilters.vue';
import BackButton from '@/components/appSections/BackButton.vue';
import CandidateSearchResult from '@/components/candidateSearch/CandidateSearchResult.vue';

export default {
  name: 'CandidatesSearch',
  components: { BackButton, CandidateSearchFilters, CandidateSearchResult },
};
</script>

<style scoped lang="stylus">

</style>
