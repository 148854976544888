// eslint-disable-next-line import/named
import {
  getToken, tokenStatus, refreshToken,
} from '@/services/auth-service';
import axios from 'axios';

const hostName = process.env.VUE_APP_BACKEND_URL || window.location.origin;

const baseUrl = `${hostName}${process.env.VUE_APP_BASE_URL}`;

const AxiosApi = axios.create({
  baseURL: baseUrl,
  timeout: 1000,
});

AxiosApi.defaults.headers.common.Accept = 'application/json';
AxiosApi.interceptors.request.use(async (config) => {
  if (config.url === '/auth/login/' || config.url === '/auth/refresh/') {
    return config;
  }
  const authStatus = await tokenStatus();
  if (!authStatus) {
    const controller = new AbortController(AxiosApi);
    controller.abort();
    window.location = '/login/';
  }
  if (authStatus === 'refresh') {
    await refreshToken(AxiosApi);
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

AxiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error) {
      const originalRequest = error.config;
      if (
        error.response.status === 401
        && error.response.data.detail === 'access_token_expired'
        // eslint-disable-next-line no-underscore-dangle
        && !originalRequest._retry
      ) {
        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;
        const tokenRefreshed = await refreshToken(AxiosApi);
        if (tokenRefreshed) {
          return AxiosApi(originalRequest);
        }
      }
      if (error.response.status === 401 && error.config.url === '/login/') {
        window.location = '/login/';
      }
    }
    return Promise.reject(error);
  },
);

// eslint-disable-next-line import/prefer-default-export
export { AxiosApi };
