import CandidatesSearch from '@/views/candidates/CandidatesSearch.vue';
import CandidateCard from '@/views/candidates/CandidateCard.vue';
import CandidateCreate from '@/views/candidates/CandidateCreate.vue';
import CandidateEdit from '@/views/candidates/CandidateEdit.vue';
import CandidatesDashboard from '@/views/candidates/CandidatesDashboard.vue';

export default [
  {
    path: '/candidates/',
    name: 'candidates',
    component: CandidatesDashboard,
    meta: {
      requiresLogin: true,
      title: 'Candidates',
    },
  },
  {
    path: '/candidates-search/',
    name: 'candidates-search',
    component: CandidatesSearch,
    meta: {
      requiresLogin: true,
      title: 'Search candidates',
    },
  },
  {
    path: '/candidate/:id/',
    name: 'candidate-card',
    component: CandidateCard,
    meta: {
      requiresLogin: true,
      title: 'Candidate Card',
    },
  },
  {
    path: '/candidates-create/',
    name: 'candidates-create',
    component: CandidateCreate,
    meta: {
      requiresLogin: true,
      title: 'Create Candidate',
    },
  },
  {
    path: '/candidates-edit/:id/',
    name: 'candidates-edit',
    component: CandidateEdit,
    meta: {
      requiresLogin: true,
      title: 'Edit Candidate',
    },
  },
];
