<template lang="pug">
  #app
    vue-extend-layouts
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout';

export default {
  name: 'app',
  components: { VueExtendLayouts },
};
</script>

<style lang="stylus">
*
  box-sizing border-box

html, body
  margin 0 auto
  width 100%
  height 100%
  padding 0

#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50
  height 100%

  a
    cursor pointer

  .error
    color red;
</style>
