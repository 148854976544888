<template lang="pug">
.candidates
  h1 Candidates Home
  router-link(
    tag="button",
    :to="{ name: 'candidates-search'}",
  ) Find Candidates
  router-link(
    tag="button",
    :to="{ name: 'candidates-create'}",
  ) Create Candidate
  Dashboard
</template>

<script>
import Dashboard from '@/components/dashboard/Dashboard.vue';

export default {
  name: 'CandidatesDashboard',
  components: { Dashboard },
};
</script>

<style scoped lang="stylus">

</style>
