<template lang="pug">
.buttons
    button(@click="signIn") Sign In
    button(@click="signIn") Sign In
</template>

<script>
export default {
  name: 'AuthButtons',
  methods: {
    signIn() {
      this.$router.push({ name: 'login' });
    },
  },
};

</script>
