<template lang="pug">
.login
  BackButton(back_url_name="landing")
  .form
    .input-field
      .label Username
      input(type="text", v-model="username")
    .input-filed
      .label Password
      input(type="password", v-model="password")
      .error(v-if="error") {{ error }}
  .actions
    button(type="submit" @click="submitForm") Login
    button Cancel
</template>

<script>
import Home from '@/views/Home.vue';
import Landing from '@/views/public/Landing.vue';
import { userLogin } from '@/services/auth-service';
import { AxiosApi } from '@/services/axios-api';
import BackButton from '@/components/appSections/BackButton.vue';

export default {
  name: 'Index',
  components: {
    BackButton,
    Home,
    Landing,
  },
  data() {
    return {
      is_auth: false,
      username: '',
      password: '',
      error: '',
    };
  },
  methods: {
    submitForm() {
      this.error = '';
      userLogin(AxiosApi, {
        username: this.username,
        password: this.password,
      })
        .then(() => {
          // TODO need redirect to previously page if exists
          this.$router.push({ name: 'home' });
        })
        .catch((err) => {
          this.error = err.response.data.detail;
        });
    },
  },
};
</script>

<style scoped lang="stylus">

</style>
