<template lang="pug">
.vacancies-search
  h1 Create Vacancy
</template>

<script>
export default {
  name: 'VacanciesCreate',
};
</script>

<style scoped lang="stylus">

</style>
