import VacanciesDashboard from '@/views/vacancies/VacanciesDashboard.vue';
import VacanciesCreate from '@/views/vacancies/VacanciesCreate.vue';

export default [
  {
    path: '/vacancies/',
    name: 'vacancies',
    component: VacanciesDashboard,
    meta: {
      requiresLogin: true,
      title: 'Vacancies',
    },
  },
  {
    path: '/vacancies-create/',
    name: 'vacancies-create',
    component: VacanciesCreate,
    meta: {
      requiresLogin: true,
      title: 'Vacancies Create',
    },
  },
];
