import Vue from 'vue';
import { tokenStatus } from '@/services/auth-service';
import layout from 'vue-extend-layout';
import App from './App.vue';
import router from './router';
import store from './store/index';

Vue.config.productionTip = false;

const preparedRouter = (to, from, loggedStatus) => {
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (loggedStatus) {
      return {};
    }
    return { name: 'login' };
  }
  if (loggedStatus) {
    return { name: 'home' };
  }
  if (to.path === '/' || to.path === '/login') {
    return {};
  }
  return { name: 'login' };
};
router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || 'RecRes';
  const loginStatus = await tokenStatus();
  const changeRouter = preparedRouter(to, from, loginStatus);
  if (Object.keys(changeRouter).length) {
    next(changeRouter);
  } else {
    next();
  }
});

new Vue({
  store,
  router,
  ...layout,
  render: (h) => h(App),
}).$mount('#app');
