<template lang="pug">
.pdf-viewer
  embed(:src="file")
</template>

<script>
import { AxiosApi } from '@/services/axios-api';

export default {
  name: 'PdfViewer',
  data() {
    return {
      file: null,
    };
  },
  created() {
    AxiosApi.get(`/file/${this.$route.params.id}`, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const objectUrl = window.URL.createObjectURL(blob);
        this.file = objectUrl;
      });
  },
};
</script>

<style scoped lang="stylus">
.pdf-viewer
  height 100%
  embed
    height 100%
    width 100%
</style>
