<template lang="pug">
.candidate-search-result
  .result(v-if="isSearching && !candidatesList.length")
    h3 Please try enother filters
  .result(v-else-if="candidatesList.length")
    table
      th(v-for="field in fieldList" :key="field.name") {{ field.title }}
      tr(v-for="item in candidatesList" :key="item.id")
        td
          router-link(
            :to="{ name: 'candidate-card', params: { id: item.id } }"
          ) {{ item.first_name }}
        td {{ item.current_position }}
        td {{ item.experience.years }} years, {{ item.experience.months }} months
        td {{ item.cv_path }}
      button.next-page(v-if="nextPage" @click="getNextList") Load More
  .result(v-else)
    h3 Please add filters to show candidates
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CandidateSearchResult',
  data() {
    return {
      fieldList: [
        { title: 'First name', name: 'first_name' },
        { title: 'Current Position', name: 'current_position' },
        { title: 'Experience', name: 'experience' },
        { title: 'CV', name: 'cv_path' },
      ],
    };
  },
  computed: {
    ...mapState(['candidateSearchResult']),
    ...mapGetters([
      'getCandidateSearchResult',
      'getNextPage',
      'getIsSearching',
    ]),
    candidatesList: (store) => store.getCandidateSearchResult,
    nextPage: (store) => store.getNextPage,
    isSearching: (store) => store.getIsSearching,
  },
  methods: {
    getNextList() {
      this.$store.dispatch('getNextList');
    },
  },
};
</script>

<style scoped lang="stylus">
table
  width 100%
.result
  margin-top 40px
</style>
