const tokenStatus = async () => {
  if (localStorage.getItem('at') && localStorage.getItem('expires')) {
    let utc = new Date();
    const offset = utc.getTimezoneOffset();
    utc = new Date(utc.getTime() + offset * 60000);
    try {
      // eslint-disable-next-line no-new
      new Date(localStorage.getItem('expires'));
    } catch (error) {
      return '';
    }
    if (new Date(localStorage.getItem('expires')) > utc) {
      return 'ok';
    }
    return 'refresh';
  }
  return false;
};

const setToken = (token, expires) => {
  localStorage.setItem('at', token);
  localStorage.setItem('expires', expires);
};

const destroyToken = () => {
  localStorage.removeItem('at');
  localStorage.removeItem('expires');
};

const userLogin = (conn, userCredentials) => new Promise((resolve, reject) => {
  conn.post('/auth/login/', {
    username: userCredentials.username,
    password: userCredentials.password,
  },
  {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
  })
    .then((response) => {
      // eslint-disable-next-line camelcase
      const { access_token, expires } = response.data;
      setToken(access_token, expires);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
});

const getToken = () => localStorage.getItem('at');

const userLogout = (conn) => {
  destroyToken();
  conn.post('/auth/logout/').catch(() => {});
};

const refreshToken = async (conn) => {
  const headers = { Authorization: `Bearer ${getToken()}` };

  return conn.get('/auth/refresh/', { withCredentials: true, headers }).then((response) => {
    // eslint-disable-next-line camelcase
    const { access_token, expires } = response.data;
    setToken(access_token, expires);
    return true;
  }).catch((error) => {
    if (error.response.status === 401) {
      destroyToken();
    }
    return false;
  });
};

export {
  tokenStatus, getToken, userLogin, userLogout, refreshToken,
};
