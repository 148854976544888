<template lang="pug">
.vacansies
  h1 Vacancies
  router-link(
    tag="button",
    :to="{ name: 'vacancies-create'}",
  ) Create Vacancy
  Dashboard
</template>

<script>
import Dashboard from '@/components/dashboard/Dashboard.vue';

export default {
  name: 'VacanciesHome',
  components: { Dashboard },
};
</script>

<style scoped lang="stylus">

</style>
